
import { ElForm } from "element-plus"
import ApiService from "@/core/services/ApiService"

import { computed, defineComponent, onMounted, ref, toRefs, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import { RuleTypes, getRule } from "@/core/helpers/rules"
import { UserInvitation } from "@/core/models/UserInvitation"
import { RoleCategoryDto } from "@/core/models/Role"
import { RoleCategory } from "@/core/enums/enums"

export default defineComponent({
  name: "user-component",
  components: {},
  props: ["add", "id", "closed"],
  emits: ["closed", "getInvitationList"],
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const { add, id } = toRefs(props)

    type FormInstance = InstanceType<typeof ElForm>

    const formSize = ref("")
    const ruleFormRef = ref<FormInstance>()
    const businessDetail = ref([])
    const roleCategoryList = ref<RoleCategoryDto[]>([] as RoleCategoryDto[])

    const userData = ref<UserInvitation>({ branchIds: [] as string[] } as UserInvitation)

    const getUser = async id => {
      const { data } = await ApiService.get("user/" + id)
      userData.value = data
    }

    watch(
      () => props.id,
      async newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          await getUser(newValue)
          getRole()
        }
      }
    )

    watch(
      () => props.add,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          userData.value = { branchIds: [] as string[] } as UserInvitation
          getRole()
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined || props.add == true)

    const rules = ref({
      branchIds: getRule(RuleTypes.TEXTMAX, "Şube"),
      userTypeId: getRule(RuleTypes.SELECT, "Kullanıcı Türü"),
      emailAddress: getRule(RuleTypes.MAIL, "E-Mail Adresi"),
    })
    const getBusinessDetail = async () => {
      const { data } = await ApiService.get("business/detail")
      businessDetail.value = data
        .filter(x => x.branches.length > 0)
        .map(x => ({ branches: x.branches, title: x.title, id: x.id }))

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      businessDetail.value.forEach((element: any) => {
        element.id = null
      })
    }

    const getRole = async () => {
      const config = {
        params: {
          userTypeCategoryList: [RoleCategory.FounderManager, RoleCategory.Personnel],
        },
      }
      const { data } = await ApiService.query("user/role-category", config)
      roleCategoryList.value = data
    }

    const addUser = async () => {
      const data = { ...userData.value, branchIds: userData.value.branchIds.map(x => x[1]) }
      await ApiService.post("invitation/add", data)
      emit("getInvitationList")
      router.push({ name: "user-list" })
    }

    const updateUser = async () => {
      const config = {
        branchIds: userData.value.branchIds.map(x => x[1]),
        userId: route.params.user_id,
      }
      await ApiService.post("user/edit", config)
      router.push({ name: "user-list" })
    }

    function addOrUpdate(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          if (props.add) {
            addUser()
          } else {
            updateUser()
          }
        }
      })
    }

    const resetForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.resetFields()
    }

    const casProps = {
      multiple: true,
      children: "branches",
      label: "title",
      value: "id",
    }

    onMounted(async () => {
      await getBusinessDetail()
    })

    return {
      userData,

      showDrawer,

      formSize,
      ruleFormRef,

      casProps,
      rules,

      addOrUpdate,
      resetForm,
      getRole,
      roleCategoryList,

      businessDetail,
      newUser: add,
      activeUserId: id,
    }
  },
})
