
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import UserComponent from "@/components/general/definitions/user/user.vue"
import { useRoute, useRouter } from "vue-router"
import { Invitation, RoleUser } from "@/core/models/User"
import { dateForm, DateFormatTypes } from "@/core/helpers/dateformat"
import { Role } from "@/core/enums/enums"

export default defineComponent({
  name: "user-list",
  components: {
    UserComponent,
  },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const newUser = ref(false)
    const activeUserId = ref<string | undefined>()
    const invitationList = ref<Invitation[]>([])
    const userList = ref<RoleUser[]>([])

    const isUserTableLoading = ref(true)
    const isInvitationTableLoading = ref(true)

    watch(
      () => props.add,
      newValue => {
        newUser.value = true == newValue
      }
    )

    watch(
      () => route.params.user_id,
      newValue => {
        activeUserId.value = newValue as string
      }
    )

    async function getUserList() {
      isUserTableLoading.value = true
      const { data } = await ApiService.get("user")
      userList.value = data
      isUserTableLoading.value = false
    }

    async function getInvitationList() {
      isInvitationTableLoading.value = true
      const { data } = await ApiService.get("invitation/account")
      invitationList.value = data
      isInvitationTableLoading.value = false
    }

    onMounted(async () => {
      newUser.value = props.add
      activeUserId.value = route.params.user_id as string
      await getUserList()
      await getInvitationList()
    })

    const handleUserDrawerClose = () => {
      router.push({ name: "user-list" })
    }

    return {
      getInvitationList,

      newUser,
      activeUserId,

      userList,
      invitationList,

      Edit,
      DateFormatTypes,
      Role,

      isUserTableLoading,
      isInvitationTableLoading,

      handleUserDrawerClose,
      dateForm,
    }
  },
})
